<template>
    <div>
        <div class="create-simulation-container wwd-column">
            <div class="switch-wrapper">
                <it-switch v-model="copy_scenario" class="it-switch" type="warning" label="Copy existing scenario" />
            </div>
            <div v-if="copy_scenario" class="wwd-row select-wrapper">
                 <it-select
                    class="itselect"
                    label-top="Select scenario*"
                    optionLabel="name"
                     optionValue="name"
                    v-model="scenario_selection"
                    :options="scenarios"
                    v-tooltip="scenario_selection"
                    />
            </div>
            <div class="input-group wwd-col">
                <div style="width: 30rem">
                    <it-input v-model="name" class="name" prefix-icon="short_text" label-top="Scenario name* "/>
                </div>
                <div class="wwd-row size">
                    <it-input v-if="!copy_scenario" v-model="rowNumber" type="number" prefix-icon="table_rows" label-top="Rows* " class="wwd-color" /> 
                    <it-input v-if="!copy_scenario" v-model="columnNumber" type="number" prefix-icon="view_week" label-top="Columns* " class="wwd-color" />
                </div>
            </div>
            <div class="add-tag-group">
                <it-input :placeholder="copy_scenario ? 'Tags inhrerit from parent' : ''" :disabled="copy_scenario" v-model="scenario_tag" style="width: 21rem" class="scenario-tag-name" prefix-icon="label" label-top="Add scenario tag "/>
                <Button :disabled="copy_scenario" @click="addTag()" icon="pi pi-plus" label="Add tag" class="p-button p-button-secondary p-button-sm add-label-button"></Button>
            </div>
            <div class="tags-info-group font-color">
                <span> Tags </span>
                <i v-tooltip="'Tags are used to identify scenarios that matches the model being run. They are optional.'" class="pi pi-question-circle"></i>
            </div>
            <div class="tags-group">
                <Tag @click="deleteTag(index)" v-for="(tag, index) in tags" :key="tag" :index="index" severity="warning" :value="tag" class="scenario-tag"> </Tag>
            </div>
            <small v-if="fill_error" style="color: var(--red1); margin: 0 auto"> Fill all required forms to create scenario </small>
            <small v-if="exist_error" style="color: var(--red1); margin: 0 auto"> Scenario with this name already exist </small>
        </div>
        <div class="wwd-center">
            <div class="wwd-column wwd-center">
                <!-- <it-button @click="copy_scenario ? copySimulation() : addNewSimulation(signal_type)" type="success" class="submit wwd-color"  outlined>Submit</it-button> -->
                <Button @click="copy_scenario ? copySimulation() : addNewSimulation(signal_type)" label="Create scenario" icon="pi pi-check" class="p-button p-button-primary create-label-button"></Button>
                <base-loader v-if="copy_loading" />

            </div>
        </div>         
    </div>
</template>

<script>
    import BaseLoader from '@/components/ui_items/BaseLoader.vue'
    import Tag from 'primevue/tag';
    import Button from 'primevue/button'
    export default {
        components: {
            BaseLoader,
            Tag,
            Button
        },
        data() {
            return {
                name: null,
                rowNumber: 6,
                columnNumber: 12,
                copy_scenario: true,
                scenarios: [],
                scenario_selection: null,
                copy_loading: false,
                fill_error: false,
                exist_error: false,
                scenario_tag: '',
                tags: [],
                models: [
                    {
                    label: 'EG300'
                    },
                    {
                    label: 'EG500'
                    },
                    {
                    label: 'EG700'
                    },
                ]
            }
        },
        methods: {
            async addNewSimulation() {
                this.fill_error = false
                this.exist_error = false
                if (!this.name) {
                    this.fill_error = true
                    return
                }
                if (Object.keys(this.$store.getters.listOfSimulations).includes(this.name)) {
                    this.exist_error = true
                    return
                }
                if (!this.rowNumber || !this.columnNumber) {
                    this.fill_error = true
                    return
                }
                if (this.name.includes('.') || this.name.includes(',') || this.name.includes('/')) {
                    this.fill_error = true
                    return
                }
                
                await this.$store.dispatch('createSimulation', {
                    rowNumber: this.rowNumber > 50 ? 50 : this.rowNumber,
                    columnNumber: this.columnNumber > 50 ? 50 : this.columnNumber,
                    scenario_name: this.name,
                    scenario_tags: this.tags
                })
                this.$emit('close-dialog') 
            },
            async copySimulation() {
                this.fill_error = false
                this.exist_error = false
                if (!this.name) {
                    this.fill_error = true
                    return
                }
                if (Object.keys(this.$store.getters.listOfSimulations).includes(this.name)) {
                    this.exist_error = true
                    return
                }
                if (this.name.includes('.') || this.name.includes(',') || this.name.includes('/')) {
                    this.fill_error = true
                    return
                }
                if (!this.scenario_selection) {
                    this.fill_error = true
                    return
                }
                await this.$store.dispatch('copySimulation', {
                    scenario_to_copy: this.scenario_selection,
                    scenario_name: this.name
                })
                this.$emit('close-dialog') 
            },
            addTag() {
                if (this.scenario_tag) {
                    this.tags.push(this.scenario_tag)
                    this.scenario_tag = ''
                }
            },
            deleteTag(index) {
                this.tags.splice(index, 1)
            },
        },
        mounted() {
            this.scenarios = Object.keys(this.$store.getters.listOfSimulations)
        },
    }
</script>


<style scoped>
    .create-simulation-container {
        gap: 0.8rem;
    }

    .scenario-tag-name {
        width: fit-content !important;
    }
    .submit {
        margin-top: 10px;
        width: 100px;
        border-color: var(--woodward_red);
        color: var(--woodward_red);
    }
    .wwd-color:hover {
        background-color: #9e211f15;
    }
    .switch-wrapper {
        display: flex;
        padding: 0.6rem 0;
        width: 100%;
    }
    .it-switch-wrapper {
        width: 13rem !important;
    }
    .itselect:deep(.it-select-selection) {
        width: 30rem !important;
    }
    .select-wrapper {
        margin-top: 10px;
    }
    .input-group {
        display: flex;
        gap: 0.2rem;
        flex-direction: column;
    }
    .add-tag-group {
        display: flex;
        flex-direction: row;
        height: 2rem;
        align-items: center;
        margin-top: 1rem;
        gap: 0.2rem;
        width: 100%;
    }
    .add-label-button {
        margin-top: 1.5rem;
    }
    .create-label-button {
        margin-top: 1.5rem;
        width: 50%;
        background-color: rgb(129, 162, 252);
        border-color: rgb(129, 162, 252);
    }
    .create-label-button:hover {
        background-color: rgb(137, 168, 253);
        border-color: rgb(137, 168, 253);
    }
    #pv_id_1_overlay {
        width: 100rem !important;
    }
    .tags-info-group {
        display: flex;
        flex-direction: row;
        gap: 0.2rem;
        margin-top: 0.8rem;
        
    }
    .tags-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;
        gap: 0.2rem;
        height: max-content;

    }
    .tags-group > * {
        width: 7rem;
        transition: 0.3s all;
    }
    .size {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
    .scenario-tag {
        background-color: var(--color5);
        color: var(--font1);
        width: fit-content;
    }
    .scenario-tag:hover {
        cursor: pointer;
        background-color: rgb(110, 0, 0);
        content: 'Delete' !important;
    }
    ::v-deep(.p-dropdown-label) {
        display: none !important;
    }
    ::v-deep(.p-dropdown-trigger) {
        height: 2.15rem !important;
        background-color: #607d8b;
        border-radius: 4% !important;
        color: var(--font1);
    }

</style>