<template>
    <Card class="simubox-card base-card">
        <template #title>
            <span> Scenario selection </span>
            <div class="wwd-row">
                <span class="load-info-label font-sans"> Current scenario:  </span>
                <div class="load-info-button"> 
                    <!-- <Button :label="currentModel" class="p-button-outlined p-button-raised info-button" /> -->
                    <Tag class="tag" :value="chosedScenario ? chosedScenario : 'None'"></Tag>
                </div>
            </div>
        </template>
        <template #content>
            <div class="content-wrapper">
                <OrderList class="order-list" v-model="dataFiltered" v-model:selection="selection" listStyle="height:auto">
                    <template #header>
                        <div class="wwd-row wwd-sb">
                            <span> List of your scenarios </span>
                            <div class="filtering-wrapper">
                                <div>
                                    <Checkbox v-model="tag_filter" :binary="true" id="tag-filter" />
                                    <label for="tag-filter" class="ml-2"> Show only for running model </label>
                                    
                                </div>
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText type="text" v-model="scenario_filter" placeholder="Search" />
                                </span>
                                <Button label="Create scenario" icon="pi pi-plus" class="create-button p-button p-button-sm p-button-text"
                                    @click="showCreateScenarioDialog()" />
                            </div>
                        </div>
                    </template>
                    <template #item="slotProps">
                        <div class="product-item-new">
                            <div class="image-container-new">
                            </div>
                            <div class="product-list-detail-new">
                                <span class="model-name-new">{{slotProps.item.scenario_name}}</span>
                                <div class="wwd-row scenario-tags-container">
                                    <i class="pi pi-tags product-category-icon-new"></i>
                                    <div class="scenario-tags-row">
                                        <Tag v-for="tag in slotProps.item.scenario_tags.split(',')" :key="tag" class="tag" :value="tag"></Tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                
                </OrderList>
                <div class="load-info">
                    <div class="load-simu-button">
                        <Button @click="changeScenario()" :loading="scenario_loading" label="Change" class="p-button-success p-button-raised load-simu-button-item" />
                    </div>
                </div>
            </div>   
            <teleport to="body">
            <transition name="modal">    
                <base-dialog @close="toggleCreateSimulationDialog" :open="createSimulationDialogVisible" :top="'10'" :width="'40'">
                        <CreateSimulationWrapper @close-dialog="closeCreateSimulationDialog" />
                </base-dialog>
            </transition>
        </teleport>     
        </template>    
    </Card>   
    
</template>

<script>
    import Card from 'primevue/card';
    import Button from 'primevue/button'
    import OrderList from 'primevue/orderlist';
    import Checkbox from 'primevue/checkbox';
    import Tag from 'primevue/tag';
    import InputText from 'primevue/inputtext'
    import BaseDialog from '@/components/ui_items/BaseDialog.vue'
    import CreateSimulationWrapper from '@/components/functions/CreateSimulationWrapper.vue'
    
    export default {
        components: {
            Card,
            Button,
            OrderList,
            Tag,
            InputText,
            Checkbox,
            BaseDialog,
            CreateSimulationWrapper
        },
        data() {
            return {
                selection: null,
                scenario_loading: null,
                scenario_filter: null,
                tag_filter: false,
                createSimulationDialogVisible: false,
            }
        },
        methods: {
            async getListOfScenarios() {
                await this.$store.dispatch('listOfSimulations')
            },
            async changeScenario() {
                this.scenario_loading = true
                setTimeout(() => {
                    this.scenario_loading = false
                }, 500);
                this.$store.commit('setUrl', {
                    chosed_simulation : this.selection[0].scenario_name
                })

                this.$store.dispatch('firebase')
            },
            showCreateScenarioDialog() {
                this.createSimulationDialogVisible = true
            },
            toggleCreateSimulationDialog() {
                this.createSimulationDialogVisible = !this.createSimulationDialogVisible
            },
            closeCreateSimulationDialog() {
                this.getListOfScenarios()
                this.toggleCreateSimulationDialog()
            },
            setTagFilter()  {
                if (['No data', 'unkown', 'No model running', 'None'].includes(this.currentModel) )  {
                    this.tag_filter = false
                } else {
                    this.tag_filter = true
                }
            }
        },
        computed: {
            scenariosList() {
                return this.$store.getters.listOfSimulations
            },
            chosedScenario() {
                return this.$store.getters.chosedScenario
            },
            dataFilteredByTags() {
                return this.tag_filter ? this.scenariosStructured.filter(scenario => scenario.scenario_tags.split(',').some(tag => this.currentModel.includes(tag))) : this.scenariosStructured
            },
            dataFiltered() {
                return this.scenario_filter ? this.dataFilteredByTags.filter(element => element.scenario_name.toLowerCase().includes(this.scenario_filter.toLowerCase())) : this.dataFilteredByTags
            },
            currentModel() {
                return this.$store.getters.currentModel
            },
            scenariosStructured() {
                return Object.keys(this.scenariosList).map(key => ({
                    scenario_name: key,
                    ...this.scenariosList[key]
                }))
            },
        },
        mounted() {
            this.getListOfScenarios()
            this.setTagFilter()
        },
        watch: {
            currentModel() {
                this.setTagFilter()
            }
        }
    }
</script>

<style scoped>
.simubox-card {
    height: 31rem;
}
.simubox-card:deep(.p-card-title) {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}
.card {
	background: #fff;
	padding: 2rem;
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	border-radius: 4px;
	margin-bottom: 2rem;
}
.content-wrapper {
    display: flex;
    flex-direction: column;
}
 
.order-list {
    min-width: 60%;
    max-height: 30rem;
}
.order-list:deep(.p-orderlist-list) {
    max-height: 16.5rem !important;
}
.model-name {
    font-size: 1.1rem;
}
.load-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.product-item-new {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 3.5rem;
}
.image-container-new img {
    width: 130px;
    height: 60px;
    margin-right: 0.5rem;
}
.product-list-detail-new {
    display: flex;
    flex-direction: column;
}
.model-name-new {
    font-weight: 500;
    font-size: 1.05rem;
    margin-bottom: 0.5rem;
}
.product-category-icon-new {
    margin-right: 0.3rem;
}
.load-info-label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 400 !important;
}
.load-simu-button-item {
    min-width: 12rem;
    min-width: 10rem;
    border: 1px var(--color7) solid !important;
}
.load-simu-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.load-info-label {
    margin-top: 0.3rem;
    margin-right: 0.4rem;
    font-size: 1.1rem;
}
.p-tag {
    background-color: var(--color4) !important;
    font-weight: 600;
    border-color: var(--color8);
}
.filtering-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
}
.scenario-tags-row {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
}
.scenario-tags-container {
    display: flex;
    align-items: center;
}

.p-button-danger {
    background: #9e211f !important;
}

.create-button {
    color: var(--font1) !important
}

@media (max-width: 700px) {
    .scenario-tags-container {
        display: none;
    }
}
 
</style>