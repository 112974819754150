<template>
    <div class="settings-container">
        <SettingsConnect class="settings-connect" />
        <SimuControl class="simu-control" />
        <!-- <UserAccount class="user-account" /> -->
        <ModelControl class="model-control" />
        <DevicePreview class="device-preview" />
        <!-- <CreateScenarioPanel class="create-scenario-panel"/> -->
        <HmiControl class="hmi-control"/>
    </div>
</template>

<script>
import SettingsConnect from '@/components/settings/SettingsConnect.vue'
import SimuControl from '@/components/settings/SimuControl.vue'
import HmiControl from '@/components/settings/HmiControl.vue'
// import UserAccount from '@/components/settings/UserAccount.vue'
import ModelControl from '@/components/settings/ModelControl.vue'
import DevicePreview from '@/components/settings/DevicePreview.vue'

    export default {
        components: {
            SettingsConnect,
            SimuControl,
            HmiControl,
            // UserAccount,
            ModelControl,
            DevicePreview
        },
        created() {
            if (this.$CookieConsent.getUserPreferences().acceptedCategories.includes('necessary')) {
                const scenario = localStorage.getItem('scenario')
                if (scenario) {
                    this.$store.commit('setUrl', {chosed_simulation: scenario})
                }
            }
        }
    }
</script>

<style scoped>
.settings-container {
    display: flex;
    flex-flow: row wrap;
    padding: 0 0.3rem;
    gap: 0.8rem;
    width: 100%;
}
.settings-connect {
    flex: 1 40%;
}
.simu-control {
    flex: 1 40%;
}
.user-account {
    flex: 1 25%;
}
.hmi-control {
    flex: 1 auto;
}
.model-control {
    flex: 1 55%;
}
.device-preview {
    flex: 1 38%;
}

@media (max-width: 1000px) {
    .settings-container {
        display: flex;
        flex-direction: column;
        padding: 0 0.3rem;
        gap: 1rem;
        width: 100%;
    }
    .settings-container > * {
        width: 100% !important; 
    }
}

</style>