<template>
    <div>

        <teleport to="body">
            <base-dialog :top="'10'" :width="'30'" :open="show_download_dialog" @close="toggleDownloadDialog()">
            <div class="dialog-container">
                <h3 class="dialog-header"> Download files from your Simubox </h3>
                <span class="dialog-description"> All files you want to download from your simubox should be placed in the <b style="color: var(--green1);">File-Transfer/Download</b> folder. You will then see them in the list below, after pressing the refresh button.</span>
                <span class="dialog-description"> Allowed file types: .wset, .wtool, .pdf, .xlsx </span>
                <Button :disabled="download_files_loading" label="Refresh" icon="pi pi-refresh" class="refresh-button p-button p-button-text extend-button" @click="getDownloadList()"></Button>
                <ProgressSpinner v-if="download_files_loading"></ProgressSpinner>
                <DataTable style="overflow: auto" class="p-datatable-sm"  :value="$store.getters.downloadFiles" v-else>
                    <Column>
                    
                        <template #body>
                            <i class="pi pi-file"></i>
                        </template>
                    
                    </Column>
                    <Column field="name" header="Name"></Column>
                    <Column>
                        <template #body="slotProps">
                            <Button @click="downloadFile(slotProps.data.name)" class="p-button p-button-text p-button-secondary" icon="pi pi-download" label="Download"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>    

                
            </base-dialog>
        </teleport>

        <teleport to="body">
            <base-dialog :top="'10'" :width="'40'" :open="show_upload_dialog" @close="toggleUploadDialog()">
            <div class="dialog-container">
                <h3 class="dialog-header"> Upload files to your simubox </h3>
                <span class="dialog-description"> All files you want to upload from your PC will be placed in the <b style="color: var(--green1);">File-Transfer/Upload</b> folder.</span>
                <span class="dialog-description"> Allowed file types: .wset, .wtool </span>
                <div class="wwd-row warning-description">
                    <i style="color: var(--yellow)" class="pi pi-exclamation-triangle"></i>
                    <span> Files with the same name will be overwritten! </span>
                </div>
                <FileUpload :previewWidth="0" name="files[]" customUpload @uploader="onAdvancedUpload($event)" :multiple="true">
                    <template #empty>
                        <div class="flex flex-col" v-if="file_uploading">
                            <p class="text-secondary">Uploading file...</p>
                            <div>
                                <ProgressBar unstyled mode="indeterminate" style="height: 6px"></ProgressBar>
                            </div>
                        </div>
                        <p v-else class="text-secondary">Drag and drop files here or click choose to upload.</p>
                    </template>
                </FileUpload>
            </div>    

                
            </base-dialog>
        </teleport>


        <Card class="userinfo-card base-card font-sans">
            <template #title>
                SimuBox Control
            </template>
            <template #content>
                <div class="wwd-column userinfo-wrapper" v-if="$store.getters.vmId !== 'Simubox not assigned'">

                    <div class="id-version-wrapper">
                        <div class="form-container">
                            <label class="label-font" for="username1">SimuBox UID</label>
                            <div class="control-form-wrapper">
                                <div class="input-wrapper">
                                    <it-input disabled v-model="$store.getters.vmId" class="connect-input p-inputtext-sm" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="form-container">
                            <label class="label-font" for="username1">Version</label>
                            <div class="control-form-wrapper">
                                <div class="input-wrapper">
                                    <it-input disabled v-model="$store.getters.vmVersion" class="connect-input p-inputtext-sm" type="text" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container">
                        <label class="label-font" for="username1">Simubox Remote Desktop</label>
                        <div class="control-form-wrapper">
                            <div class="input-wrapper">
                                <it-input disabled @keydown.prevent v-model="$store.getters.vmRdp" class="sra-input connect-input p-inputtext-sm" type="text" /> 
                            </div>
                        </div>
                    </div>
                    <div class="status-wrapper">
                        <div class="wwd-row status-row">
                            <div class="status-icon" :class="vmStatus.style"></div>
                            <div class="session-label"> {{ vmStatus.label }} </div>
                            <span v-if="rdpReady" style="margin-left: 5px;" class="session-label">| RDP Pin: <b>{{ $store.getters.vmSraPassword }}</b> </span>
                            <i v-if="rdpReady && !copied" @click="copyToClipboard($store.getters.vmSraPassword)" class="pi copy-icon pi-copy" v-tooltip.top="'Copy to clipboard'"></i>
                            <i v-if="rdpReady && copied" class="pi check-icon pi-check"></i>
                        </div>
                        <div class="wwd-row shutdown-info" v-if="$store.getters.vmStatus === 'running'">
                            <span class="session-label"> Auto shutdown at {{ localTimeToShutdown }} (<b>{{ $store.getters.vmShutdownTimeTo }}</b>) </span>
                            <i v-tooltip="shutdown_message" style="color: var(--gray); font-size: 0.8rem;" class="pi pi-question-circle"></i>
                        </div> 
                    </div>
                    <div>
                    </div>

                    <div class="simubox-spinner" v-if="$store.getters.vmStatus === 'busy' || ($store.getters.vmStatus === 'running' && !rdpReady )">
                        <ProgressSpinner></ProgressSpinner>
                        <div class="simu-start-info">
                            <i class="pi pi-info-circle"> </i>
                            <div> Starting or stopping simubox can take up to 3-4 minutes. If the simubox takes longer to start, most likely an update is performed, which can take up to 10 minutes.</div>
                        </div>
                    </div>
                    <div class="ft-container" v-else-if="['stopped', 'running'].includes($store.getters.vmStatus) ">
                        <Button @click="openLink($store.getters.vmRdp)" v-if="rdpReady" label="Open SimuBox Remote Desktop" icon="pi pi-desktop" class="p-button  p-button-sm"></Button>
                        <Button @click="startVm()" :loading="start_stop_loading" v-if="$store.getters.vmStatus === 'stopped'" label="Turn on Simubox" icon="pi pi-power-off" class="p-button p-button-sm"></Button>
                        <Button @click="extendSession()" :loading="extending_session" v-if="$store.getters.vmStatus === 'running'" icon="pi pi-clock" class="p-button p-button-secondary p-button-text extend-button action-button p-button-sm" label="Extend session"></Button>
                        <Button @click="toggleDownloadDialog()" v-if="$store.getters.vmStatus === 'running'" icon="pi pi-cloud-download" class="p-button p-button-secondary p-button-text download-button action-button p-button-sm" label="Download files from Simubox"></Button>
                        <Button @click="toggleUploadDialog()" v-if="$store.getters.vmStatus === 'running'" icon="pi pi-cloud-upload" class="p-button p-button-secondary p-button-text upload-button action-button p-button-sm" label="Upload files to Simubox"></Button>
                        <Button @click="stopVm()" :loading="start_stop_loading" v-if="$store.getters.vmStatus === 'running'" label="Turn off Simubox" icon="pi pi-power-off p-button-sm" class="p-button p-button-secondary p-button-text stop-button action-button p-button-sm"></Button>
                    </div>

                </div>
                <div class="loading-text" v-else>
                    SimuBox not attached to this account
                </div>
            </template>
        </Card>    
            </div>
</template>

<script>
    import Card from 'primevue/card';
    import Button from 'primevue/button'
    import BaseDialog from '@/components/ui_items/BaseDialog'
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import ProgressSpinner from 'primevue/progressspinner';
    import FileUpload from 'primevue/fileupload';
    import ProgressBar from 'primevue/progressbar'
    // import Checkbox from 'primevue/checkbox';
    export default {
        components: {
            Card,
            Button,
            BaseDialog,
            DataTable,
            Column,
            ProgressSpinner,
            FileUpload,
            ProgressBar
        },
        data() {
            return {
                simubox_busy: false,
                start_stop_loading: false,
                ip_copied: false,
                rdp_copied: false,
                extending_session: false,
                show_download_dialog: false,
                show_upload_dialog: false,
                download_files_loading: false,
                file_uploading: false,
                copied: false,
                shutdown_message: 'For optimal use of virtual machines, we automatically turn them off after a certain period of time. It can always extend the session using the extend session button. Besides, when the time to shut down is approaching you will be informed.'
            }
        },
        methods: {
            async startVm() {
                this.start_stop_loading = true
                await this.$store.dispatch('startVm')
                setTimeout(() => {
                    this.start_stop_loading = false
                }, 30000);
            },

            async stopVm() {
                this.start_stop_loading = true
                await this.$store.dispatch('stopVm')
                setTimeout(() => {
                    this.start_stop_loading = false
                }, 30000);
            },

            async extendSession() {
                this.extending_session = true
                await this.$store.dispatch('extendSession')
                this.extending_session = false
            },

            openLink(url) {
                window.open(url, '_blank').focus();
            },
            toggleDownloadDialog() {
                this.show_download_dialog = !this.show_download_dialog
                this.getDownloadList()
            },
            toggleUploadDialog() {
                this.show_upload_dialog = !this.show_upload_dialog
            },
            async getDownloadList() {
                try {
                    this.download_files_loading = true
                    await this.$store.dispatch('getDownloadList')
                } catch(err) {
                    console.log(err)
                } finally {
                    this.download_files_loading = false
                }
            },
            async downloadFile(fileName) {
                try {
                    this.$store.dispatch('downloadFile', fileName)
                } catch(err) {
                    window.alert(err)
                }
            },
            async onAdvancedUpload(event) {
                try {
                    this.file_uploading = true
    
                    let formData = new FormData()
                    for (let i = 0; i < event.files.length; i++) {
                        formData.append('files', event.files[i])
                    }

                    formData.append('type', 'multipart/form-data')

                    await this.$store.dispatch('uploadFile', formData)
                    this.$toast.add({severity:'success', summary: 'Success!', detail:`File uploaded correctly.`, life: 5000});
                } catch(err) {
                    console.log('Failed to upload file: ' + err)
                } finally {
                    this.file_uploading = false

                }
                // formData.append('type', formDataType.value)
            },
            copyToClipboard(text) {
                navigator.clipboard.writeText(text).then(() => {
                    this.$toast.add({severity:'success', summary: 'Pin copied', detail:`Use this pin to authorize remote desktop`, life: 5000});
                    this.copied = true
                    setTimeout(() => {
                        this.copied = false
                    }, 3000);
                }).catch(function(error) {
                    console.error('Error copying text: ', error);
                });
            }
        },
        computed: {
            vmStatus() {
                switch (this.$store.getters.vmStatus) {
                    case 'stopped':
                        return {label: 'SimuBox is offline', style: 'status-icon_danger'};
                    case 'running':
                        if (!this.rdpReady) {
                            return {label: 'Starting services...', style: 'status-icon_warning'};
                        } 
                        return {label: 'SimuBox is online', style: 'status-icon_success'};
                    case 'busy':
                        return {label: 'SimuBox is busy...', style: 'status-icon_warning'}
                    default:
                        return {label: 'Contacting server for information... (It might take up to 2minutes)', style: 'status-icon_unknown'}
                }
            },
            localTimeToShutdown() {
                return this.$store.getters.vmShutdownTime ? this.$store.getters.vmShutdownTime.toLocaleTimeString() : null
            },
            rdpReady() {
                return !['Simubox unreachable', 'Simubox not ready'].includes(this.$store.getters.vmRdp)
            }
        },  
    }
</script>

<style scoped>
.userinfo-card {
    height: 34rem !important;
}
.userinfo-card:deep(.p-card-content) {
    min-height: 22rem;
}
.userinfo-card:deep(.p-card-title) {
    display: flex;
    align-items: center;
    font-weight: 400;
}
.userinfo-card:deep(.p-card-footer) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.userinfo-wrapper {
    gap: 0.1rem;
}
.form-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.userinfo-icon {
    font-size: 1.7rem;
    color: var(--green2);
}
::v-deep(.it-input-wrapper--disabled) {
    border-color: var(--color10) !important;
}
.status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}
.status-icon {
    display: flex;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    margin-right: 0.5rem;
}
.status-icon_success {
    background-color: #689F38;
    border: 1px solid #6cab35;
}
.status-icon_warning {
    background-color: rgb(194, 184, 0);
    border: 1px solid rgb(241, 229, 0);
}
.status-icon_danger {
    background-color: rgb(137, 0, 0);
    border: 1px solid rgb(187, 0, 0);;
}
@keyframes spin { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }
.status-icon_unknown {
    background-color: rgb(121, 121, 121);
    border: 1px solid rgb(182, 226, 243);
    animation: spin 1s linear infinite;
}
.control-buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-right: 10%;
}
.control-form-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}
.input-wrapper {
    width: 100%;
}
.control-icon {
    font-size: 1.2rem;
}
.copy-icon {
    font-size: 1rem;
    margin-left: 0.2rem;
}
.copy-icon:hover {
    cursor: pointer;
}
.control-icon:hover {
    cursor: pointer;
}
.start-icon {
    font-size: 1.3rem;
    padding: 0.3rem;
    color: #689F38;
}
.stop-icon {
    font-size: 1.3rem;
    padding: 0.3rem;
    color: var(--red1);
}
.check-icon {
    font-size: 1rem;
    color: var(--green1);
    margin-left: 0.4rem;
}
.start-icon:hover, .stop-icon:hover {
    cursor: pointer;
}

.ft-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
    width: 100%;
    /* border: 1px solid rgba(105, 105, 105, 0.359); */
    padding: 0.5rem;
}
.ft-container > * {
    width: 100%;
}
.ft-wrapper {
    display: flex;
    flex-direction: column;
}
.start-button {
    color: #9bc477 !important;
}
.stop-button {
    color: var(--gray) !important;
}
.extend-button {
    color: var(--yellow) !important;
}

.download-button {
    color: var(--blue1) !important;
}

.upload-button {
    color: var(--blue1) !important;
}

.session-label {
    font-size: 0.8rem;
    text-wrap: nowrap;
}
.shutdown-info {
    align-items: center;
    gap: 1rem;
}
.dialog-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: var(--font1);
    height: 30rem;
    max-height: 30rem;

}
.dialog-description {
    font-size: 0.9rem;
    color: var(--font1);
}
.refresh-button {
    align-self: flex-end;
}
.warning-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem;
    color: var(--font1);
    gap: 0.3rem;
}

.sra-input {
    width: 100% !important;
}
.sra-input:hover {
    cursor: pointer !important;
}
.simu-start-info {
    display: flex;
    gap: 0.4rem;
    font-size: 0.9rem;
    align-items: center;
    margin-top: 3rem;
}
.simu-start-info > i {
    color: var(--blue1);
}
.simubox-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    flex-direction: column;
    color: var(--color7);
    font-size: 0.7rem;
    color: var(--color7);
    text-align: center;
    gap: 1rem;
}
.id-version-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.id-version-wrapper > * {
    width: 100%;
}
.loading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    font-size: 0.85rem;
    color: var(--color7);
    text-align: center;
}
.status-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>